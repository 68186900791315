import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {GetActivities, GetNextActivities} from './activities.actions';
import {
    ActivitiesService,
    IBusinessTrailRequest,
    IBusinessTrailResponse,
} from '../../modules/activities/activities.service';
import {firstValueFrom} from 'rxjs';
import {errorHandler, IErrorHandlerArgs} from 'src/app/shared/helpers/error-handler';
import {AppInsightsService} from 'src/app/core/app-insights/app-insights.service';

export interface IActivitiesState {
    activities: any[];
    loading: boolean;
    hasValue: boolean;
    error: any;
    queryParams?: IBusinessTrailRequest;
}

@State<IActivitiesState>({
    name: 'activities',
    defaults: {
        activities: [],
        loading: false,
        hasValue: false,
        error: null,
    },
})
@Injectable()
export class ActivitiesState {
    private readonly _errorHandlerArgs: IErrorHandlerArgs = {
        error: null,
        appInsightsSrv: this.insights,
        scope: 'ActivitiesState',
    };
    constructor(
        private activitiesService: ActivitiesService,
        private insights: AppInsightsService,
    ) { }

    @Selector()
    public static activities(state: IActivitiesState): any[] {
        return state.activities;
    }

    @Selector()
    public static loading(state: IActivitiesState): boolean {
        return state.loading;
    }

    @Selector()
    public static hasValue(state: IActivitiesState): boolean {
        return state.hasValue;
    }

    @Action(GetActivities)
    public async getActivities(
        ctx: StateContext<IActivitiesState>,
        {partnerParam, typeParam, inTimeline}: GetActivities
    ): Promise<any> {
        ctx.patchState({loading: true});

        try {
            const {data, continuationToken}: IBusinessTrailResponse = await firstValueFrom(
                this.activitiesService.getBusinessTrail({
                    partnerParam,
                    typeParam,
                    inTimeline,
                })
            );
            ctx.patchState({
                activities: data,
                hasValue: !!data.length,
                loading: false,
                queryParams: {
                    continuationToken,
                    partnerParam,
                    typeParam,
                    inTimeline,
                },
            });
        } catch (error) {
            errorHandler({...this._errorHandlerArgs, error});
            ctx.patchState({
                hasValue: false,
                loading: false,
                error,
            });
        }
    }

    @Action(GetNextActivities)
    public async getNextActivities(ctx: StateContext<IActivitiesState>): Promise<any> {
        const queryParams = ctx.getState().queryParams;
        const preservedData = ctx.getState().activities;

        if (queryParams?.continuationToken) {
            ctx.patchState({loading: true});
            try {
                const response: IBusinessTrailResponse = await firstValueFrom(
                    this.activitiesService.getBusinessTrail(queryParams)
                );
                ctx.patchState({
                    activities: [...preservedData, ...response?.data],
                    hasValue: !!response?.data?.length,
                    loading: false,
                    queryParams: {
                        ...queryParams,
                        continuationToken: response?.continuationToken,
                    },
                });
            } catch (error) {
                errorHandler({...this._errorHandlerArgs, error});
                ctx.patchState({
                    hasValue: false,
                    loading: false,
                    error,
                });
            }
        }
    }
}
