export enum ActivitiesType {
    OBJECT_CHANGED = 'Object changed',
    ORDER = 'Order',
    ACCOUNT_SIGN_IN = 'Account sign in',
    ACCOUNT_SIGN_UP = 'Account sign up',
    GENERAL_TERM_ACCEPTED = 'General term accepted',
    ORDERING_TERM_ACCEPTED = 'Ordering term accepted',
    PROFILE_DATA_CHANGED = 'Profile data changed',
    PROFILE_DATA_CREATED = 'Profile data created',
    PROFILE_DATA_DELETED = 'Profile data deleted',
    PARTY_CREATED = 'Party created',
    PARTY_UPDATED = 'Party updated',
    PARTY_DELETED = 'Party deleted',
}
